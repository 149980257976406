'use strict';

angular.module('casist.auth')
  .controller('LoginCtrl', ['$scope', '$rootScope', '$http', 'authService', 'Global', 'Socket', '$location', 'Restangular', 'Auth', 'Agenda', 'Permission', '$sessionStorage', '$q', '$window',
    function($scope, $rootScope, $http, authService, Global, Socket, $location, Restangular, Auth, Agenda, Permission, $sessionStorage, $q, $window) {
    $scope.inProgress = false;

    if ($rootScope.isAuth) {
      if (!$sessionStorage.accesstoken) {
        $rootScope.isAuth = false;
        Auth.clearAuthData();
      } else {
        if (isEmpty(Auth.profile().get('agendy'))) {
          $location.path('/setup');
          return;
        }
        $location.path("/");
      }
    }

    var search = $location.search();
    if (search.client && search.token && search.email) {
      $location.path('/activate');
    }
    if (search.demo === '1') {
      $('#login_email').val('demo');
      $('#login_password').val('demo');
    }
    var iOS = /(iPad|iPhone|iPod)/g.test( navigator.userAgent );
    var android = /(Android)/.test(navigator.userAgent);
    $scope.notice = '';
    if (iOS || android) {
      $scope.notice = 'Na mobilnom zariadení niektoré funkcie zatiaľ nepracujú správne. Pre korektnú funkčnosť odporúčame použiť PC.';
    }

    $scope.signIn = function() {
      var user_data = {
        "grant_type": "password",
        "email": $('#login_email').val(),
        "password": $('#login_password').val(),
        "access_type": 'ADMIN_APP'
      };
      if (user_data.email === "" || user_data.password === "")
        return;

      var body = {
        username: user_data.email,
        password: user_data.password,
        access_type: user_data.access_type
      };
      // =========================================================
      $scope.inProgress = true;
      $http.post(constants.authServer + "login", body, {timeout: 10000})
        .then(function(resp) {
          constants.serverAddress = $window.location.protocol+'//'+constants.applicationServer+'/';

          var response = resp.data;

          $sessionStorage.accesstoken = response.access_token;
          $sessionStorage.refreshtoken = response.refresh_token;
          $http.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token;
          $scope.loginForm.error_msg = "";
          $scope.inProgress = false;
          // set global parameters
          Global.set('serverAddress', constants.serverAddress);
          Global.set('serverName', response.agenda);

          var permissions = Permission.parseUserPermissions(response.perms, response.user_data);

          var profile = {
            user: resp.username,
            userData: response.user_data,
            clients: response.clients,
            prevadzky: response.prevadzky,
            pokladne: response.pokladne,
            lastPrevadzka: response.lastPrevadzka,
            lastPokladna: response.lastPokladna,
            lastUcet: response.lastUcet,
            lastVypis: response.lastVypis,
            obdobia: response.obdobia,
            obdobie: response.obdobie,
            agendy: Agenda.restangularize(angular.copy(response.agendy)),
            agendyExternal: [],
            perms: permissions,
            workspace: (response.workspace && response.workspace.workspace) ? JSON.parse(response.workspace.workspace) : null,
            layouts: response.layouts
          };

          Restangular.setBaseUrl(constants.serverAddress);

          if (isEmpty(response.agendy)) {
            $sessionStorage.settings = Global.get();
            $sessionStorage.profile = profile;
            $location.path('/setup');
            return;
          }
          delete response.settings.id;
          delete response.settings.agenda;
          Global.appendArray(response.settings);

          for (var i = 0; i < profile.agendy.length; i++) {
            profile.agendy[i] = angular.extend(Agenda.restangularize(profile.agendy[i]));
          }

          if (response.user_data.local_superuser) {
            var externeAgendy = _.filter(response.agendy, function(obj) { return obj.client !== response.user_data.local_superuser});
            if (!isEmpty(externeAgendy)) {
              for (var e = 0; e < externeAgendy.length; e++) {
                profile.agendyExternal.push(Agenda.restangularize(externeAgendy[e]));
                profile.agendy.splice(profile.agendy.indexOf(_.find(profile.agendy, {id: externeAgendy[e].id})), 1);
              }
            }
          }
          profile.agenda = _.find(response.agendy, {id: response.agenda}),
          profile.platcadph = profile.agenda.icdph !== '';
          profile.jednoducheUcto = profile.agenda.jednoduche_ucto;
          profile.eshop = profile.agenda.eshop;
          profile.svb = profile.agenda.svb;
          profile.ubytovanie = profile.agenda.ubytovanie;
          Auth.profile().load(profile);
          Auth.setAgendaHeader(response.agenda);

          $sessionStorage.settings = Global.get();
          $sessionStorage.profile = profile;
          // send login confirmed to auth service
          authService.loginConfirmed();
          // register on socket server
          Socket.emit("registerClient", {clientID: Global.get('serverAddress')+':'+Global.get('serverName')});
        }, function (data, status) {
          switch(status){
            case 404: case 0:
              $scope.loginForm.error_msg = "Server sa nepodarilo nájsť.";
              break;
            default:
              if (!data) {
                $scope.loginForm.error_msg = "Server je momentálne nedostupný."
              } else if (data.error === 'User account is disabled') {
                $scope.loginForm.error_msg = "Konto je zablokované.";
              } else if (data.error === 'User has no privileges') {
                $scope.loginForm.error_msg = "Konto nemá pridelené práva.";
              } else {
                $scope.loginForm.error_msg = "Prihlasovacie údaje sú nesprávne.";
              }
              break;
          }
          $scope.inProgress = false;
        });
      }
  }]);
